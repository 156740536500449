import React from 'react'
import tw, { styled } from 'twin.macro'

const GenericTitle = styled.h2`
  ${tw`font-mulish font-extrabold text-brand-dark-200 text-7/2 md:text-9/2 leading-tight tracking-brand`}
  margin-bottom: ${props => props.marginBottom};
`

const GenericHeading = props => <GenericTitle marginBottom={props.marginBottom}>{props.heading}</GenericTitle>

export default GenericHeading