import React from 'react'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'
import Arrow from '../assets/svgs/arrow.svg'

const CardBody = styled.div`
  ${tw`xs:p-0 sm:flex justify-between`};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  background-color: #fefefe;
  padding: 33px 30px;
  transition: all .2s;

  :hover {
    filter: drop-shadow(0px 25px 40px rgba(9, 137, 198, 0.17));
  }

  @media only screen and (min-width: 640px) {
    height: 186px;
  }
`
const CardImage = styled.img`
  ${tw`xs:w-full xs:h-auto xs:block xs:mr-0`};
  width: 115.5px;
  height: 105px;
  display: inline-block;
  margin-right: 30px;
`

const CardContent = styled.div`
  ${tw`xs:p-5 flex flex-col`}
  flex: 1;
`

const CardName = styled.h3`
  ${tw`text-brand-dark font-extrabold font-mulish leading-tight mb-2`}

  @media only screen and (min-width: 640px) {
    height: 28px;
  }
`
const CardLinkContainer = styled.div`
  ${tw`flex justify-end`}

  @media only screen and (min-width: 640px) {
    height: 24px;
  }

`

const CardLink = styled(props => <Link {...props} />)`
  ${tw`text-brand-black font-bold font-lato inline-block ml-auto opacity-60 inline-flex items-center`}
`;

const CardText = styled.p`
 ${tw`text-brand-dark font-lato text-base leading-brand-medium mb-2 sm:mb-0`}

 @media only screen and (min-width: 640px) {
   /* flex: 1; */
   word-break: break-word;
    height: calc(100% - 52px);
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
  }
`

const ServiceCard = props => {
  return (
    <CardBody>
      <CardImage src={props.cardImageSrc} alt={props.cardName} />
      <CardContent>
        <CardName>{props.cardName}</CardName>
        <CardText>{props.cardText}</CardText>
        <CardLinkContainer>
          <CardLink to={props.cardLink}>See more &nbsp; <Arrow /></CardLink>
        </CardLinkContainer>
      </CardContent>
    </CardBody>
  )
}

export default ServiceCard