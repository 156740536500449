import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import Layout from '../containers/Layout/Layout'
import Seo from '../components/Seo'
import GenericHeading from '../components/GenericHeading'
import PageCover from '../components/PageCover'
import Partners from '../components/Partners'
import NewsLetter from '../components/NewsLetter'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'
import ServicesCoverImage from '../assets/images/service-cover.png'
import ServiceImageSrcOne from '../assets/images/service-image-one.png'
import ServiceImageSrcTwo from '../assets/images/service-image-two.png'
import ServiceDots from '../assets/images/service-dots.png'

import ServiceCard from '../components/ServiceCard'

import EquipmentMaintenance from '../assets/images/equipment-maintenance.png'
import CorrosionManagement from '../assets/images/corrosion-management.png'
import AssetIntegrity from '../assets/images/asset-image.jpg'
import Procurement from '../assets/images/procurement.png'
import Automation from '../assets/images/automation.png'
import Construction from '../assets/images/services-page-construction.png'

const ServicesIntro = tw.section`max-w-screen-xl mx-auto px-5 py-20 sm:px-10 lg:px-14 xl:px-28 md:pt-32 md:pb-37 flex items-center justify-between`;
const ServicesIntroContent = tw.section`md:w-6/12 lg:w-services-content`;

const ServicesText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl leading-snug`};
  margin-bottom: ${props => props.marginBottom}
`

const ServicesPictureSection = styled.section`
  ${tw`hidden md:flex items-center justify-center md:w-4/12 lg:w-5/12 xl:w-services-picture h-services-picture relative`}
`

const ServicesWrapper = styled.section`
  ${tw`bg-brand-light-blue`}
`

const ServicesSection = tw.div`max-w-screen-xl mx-auto px-5 py-10 sm:px-10 lg:px-14 xl:px-28 lg:py-20 xl:pt-20 xl:pb-30
`
const ServicesSectionTitle = tw.h2`text-brand-dark mb-14 font-mulish tracking-brand text-9/2 sm:text-brand-big leading-tight font-extrabold`

const ServicesSectionCardsRow = styled.section`
  ${tw`grid grid-cols-1 big-tablet:grid-cols-2 gap-y-10 gap-x-11`}
`

const ServiceImageOne = styled.img`
  ${tw`absolute z-30`}
  top: 22px;
  left: -8px;
`
const ServiceImageTwo = styled.img`
  ${tw`absolute z-30`}
  bottom: 28px;
  right: 0;
`
const ServiceImageDot = styled.img`
  ${tw`absolute z-20`}
  bottom: 54px;
  left: 109px;
`

const ServiceCircle = styled.div`
  width: 278px;
  height: 278px;
  border-radius: 100%;
  background: #92a4e1;
  opacity: 0.2;
  box-shadow: -11px 22px 44px rgba(225, 254, 249, 0.19);
`

const Services = () => {
  return (
    <Layout>
      <Seo title="Our Services"/>
      <PageCover
        imageSrc={ServicesCoverImage}
        coverTitle="Our Services"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/services">Our Services</Link>
      </PageCover>
      <ServicesIntro>
        <ServicesIntroContent>
          <GenericHeading marginBottom="2.8125rem" heading="Our Services" />
          <ServicesText marginBottom="1.5rem">
            We offer full spectrum of oil and gas, construction and procurement services to help organizations attain their process capabilities.
          </ServicesText>
          <ServicesText>
            Our strength and versatility is demonstrated in wide range of core Engineering support services to clients.
          </ServicesText>
        </ServicesIntroContent>

        <ServicesPictureSection>
          <ServiceImageOne src={ServiceImageSrcOne} alt="A worker" />
          <ServiceImageDot src={ServiceDots} alt="Service Dots" />
          <ServiceCircle />
          <ServiceImageTwo src={ServiceImageSrcTwo} alt="A worker" />
        </ServicesPictureSection>
        
      </ServicesIntro>

      {/* Services Section */}
      <ServicesWrapper>
        <ServicesSection>
          <ServicesSectionTitle>Our Core Services</ServicesSectionTitle>
          <ServicesSectionCardsRow>
          <ServiceCard 
              cardImageSrc={EquipmentMaintenance}
              cardName="Equipment Maintenance"
              cardText="We work with our clients to develop suitable preventive and corrective maintenance schedules for all mechanical equipment to keep your business's equipment in reliable working condition."
              cardLink="/services/equipment-maintenance"
            />
            <ServiceCard 
              cardImageSrc={CorrosionManagement}
              cardName="Corrosion Management"
              cardText="Our corrosion management strategy is structured to influence and continually improve the economic outcome of our client’s asset by ensuring cost effective selection of materials, monitoring and chemical treatments."
              cardLink="/services/corrosion-management"
            />
            <ServiceCard 
              cardImageSrc={AssetIntegrity}
              cardName="Asset Integrity Management"
              cardText="We are in cognisance that plant integrity, safety, and reliability are major concerns to all plant operators and managers. We have continued to excel in providing unrivalled services to our clients through programs that incorporates design, maintenance, inspection, process, operations, and management concepts."
              cardLink="/services/asset-integrity-management"
            />
            <ServiceCard 
              cardImageSrc={Construction}
              cardName="Construction"
              cardText="We offer specialized wide range of engineering designs and construction services. We are committed to maintaining high standards in our projects."
              cardLink="/services/construction"
            />
            <ServiceCard 
              cardImageSrc={Procurement}
              cardName="Procurement & Man Power Supply"
              cardText="We render highly efficient procurement consulting services, intuitive strategic sourcing technologies, and data-backed market intelligence. We have practical field experience necessary to ensure successful delivery as we realize that successful procurement is driven by dedicated effort."
              cardLink="/services/procurement"
            />
            <ServiceCard 
              cardImageSrc={Automation}
              cardName="Automation & Instrumentation"
              cardText="We specialize in industrial process automation & software, electrical & instrumentation construction, plant & industrial safety system, field instrumentation & control, equipment calibration, flowmeters and power system. We provide comprehensive and tailor-made service concepts for all Motorized valves and instrumentation product range."
              cardLink="/services/automation"
            />
          </ServicesSectionCardsRow>
        </ServicesSection>
      </ServicesWrapper>

      <Partners />
      <NewsLetter />

    </Layout>
  )
}

export default Services